import ApplicationController from 'controllers/share/application_controller';

export default class NoteFormController extends ApplicationController {
  static targets = ['form', 'saveDraftButton'];
  static values = {
    translations: Object,
  };

  shareLinkId = this.getModelId('share_link_id');
  localStorageKey = `shareLink-${this.shareLinkId}-comment`;

  connect() {
    this.element.addEventListener('turbo:submit-end', async (event) => {
      this.element.innerHTML = await event.detail.fetchResponse.response.text();
      localStorage.removeItem(this.localStorageKey);
    });

    if (this.shareLinkId) {
      this.prefillForm();
    }
  }

  getModelId(name) {
    return this.element.querySelector(`input[name='${name}']`)?.value;
  }

  prefillForm() {
    const storedData = this.storedData();
    if (storedData) {
      Object.entries(storedData).forEach(([key, value]) => {
        const element = this.formTarget.querySelector(`[name='${key}']`);
        if (element) {
          element.value = value;
        }
      });
    }
  }

  storedData() {
    const data = localStorage.getItem(this.localStorageKey);
    return data ? JSON.parse(data) : null;
  }

  saveDraft(event) {
    event.preventDefault();

    this.saveDraftToLocalStorage();
    this.updateButtonText();
  }

  saveDraftToLocalStorage() {
    const formData = new FormData(this.formTarget);
    const draftData = {};

    for (const [key, value] of formData.entries()) {
      draftData[key] = value;
    }

    localStorage.setItem(this.localStorageKey, JSON.stringify(draftData));
  }

  updateButtonText() {
    const originalText = this.saveDraftButtonTarget.textContent;
    this.saveDraftButtonTarget.textContent = this.translationsValue.saved_draft;
    setTimeout(() => {
      this.saveDraftButtonTarget.textContent = originalText;
    }, 1000);
  }
}
