import ApplicationController from 'controllers/share/application_controller';

export default class InterviewKitFormController extends ApplicationController {
  static targets = ['form', 'saveDraftButton'];
  static values = {
    translations: Object,
  };

  shareLinkId = this.getModelId('share_link_id');
  pickedInterviewKitId = this.getModelId('picked_interview_kit_id');
  localStorageKey = `shareLink-${this.shareLinkId}-pickedInterviewKit-${this.pickedInterviewKitId}`;

  connect() {
    this.element.addEventListener('turbo:submit-end', async (event) => {
      window.scrollTo(0, 0);
      this.element.innerHTML = await event.detail.fetchResponse.response.text();
      localStorage.removeItem(this.localStorageKey);
    });

    if (this.shareLinkId && this.pickedInterviewKitId) {
      this.prefillForm();
    }
  }

  getModelId(name) {
    return this.element.querySelector(`input[name='${name}']`)?.value;
  }

  prefillForm() {
    const storedData = this.storedData();
    if (storedData) {
      Object.entries(storedData).forEach(([key, value]) => {
        const elements = this.formTarget.querySelectorAll(`[name='${key}']`);
        elements.forEach((element) => {
          if (element.type === 'radio') {
            element.checked = element.value === value;
          } else if (element.type === 'checkbox') {
            element.checked = Array.from(value).includes(element.value);
          } else {
            element.value = value;
          }
        });
      });
    }
  }

  storedData() {
    const data = localStorage.getItem(this.localStorageKey);
    return data ? JSON.parse(data) : null;
  }

  saveDraft(event) {
    event.preventDefault();

    this.saveDraftToLocalStorage();
    this.updateButtonText();
  }

  saveDraftToLocalStorage() {
    const formData = new FormData(this.formTarget);
    const draftData = {};

    for (const [key, value] of formData.entries()) {
      if (draftData[key]) {
        if (Array.isArray(draftData[key])) {
          draftData[key].push(value);
        } else {
          draftData[key] = [draftData[key], value];
        }
      } else {
        draftData[key] = value;
      }
    }

    localStorage.setItem(this.localStorageKey, JSON.stringify(draftData));
  }

  updateButtonText() {
    const originalText = this.saveDraftButtonTarget.textContent;
    this.saveDraftButtonTarget.textContent = this.translationsValue.saved_draft;
    setTimeout(() => {
      this.saveDraftButtonTarget.textContent = originalText;
    }, 1000);
  }
}
